<template>
  <ApiLoadingController :fetch="fetch" :params="scrimId">
    <template #default="{ data }">
      <EditScrim v-bind="data" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'

import { getScrim } from '@/api/scrims.js'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import EditScrim from '@/components/scrim/EditScrim.vue'

export default {
  name: 'ScrimEditPage',
  components: {
    EditScrim,
    ApiLoadingController,
  },
  props: {
    scrimId: px.string.isRequired,
  },
  computed: {
    params() {
      return this.scrimId
    },
  },
  methods: {
    async fetch() {
      const scrim = await getScrim(this.scrimId)
      return { scrim: scrim }
    },
  },
}
</script>

<style lang="scss" scoped></style>
