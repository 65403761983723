var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.canAccessScrimsV1
        ? _c("FormController", {
            staticClass: "form",
            attrs: { submit: _vm.submit, "clear-on-submit": false },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "form--group" }, [
                        _c("label", { attrs: { for: "title" } }, [
                          _vm._v("Match name")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "title",
                            minlength: "3",
                            maxlength: "256",
                            required: "",
                            placeholder:
                              "Pick a unique name - e.g. Augment's training team with coach as sub"
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "name", $event.target.value)
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "form--row" }, [
                        _c("div", { staticClass: "form--group" }, [
                          _c("label", { attrs: { for: "date" } }, [
                            _vm._v("Match date")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.createdDate,
                                expression: "createdDate"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "date", id: "date", required: "" },
                            domProps: { value: _vm.createdDate },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.createdDate = $event.target.value
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "form--group" }, [
                          _c("label", { attrs: { for: "time" } }, [
                            _vm._v("Match time")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.createdTime,
                                expression: "createdTime"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "time",
                              id: "time",
                              required: "",
                              step: "3600"
                            },
                            domProps: { value: _vm.createdTime },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.createdTime = $event.target.value
                              }
                            }
                          })
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form--group" },
                        [
                          _c("label", { attrs: { for: "map" } }, [
                            _vm._v("Map")
                          ]),
                          _c("MapCell", {
                            attrs: {
                              id: _vm.scrim.map,
                              size: "sm",
                              variant: "row"
                            }
                          })
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("div", { staticClass: "form--row" }, [
                        _c("div", { staticClass: "form--group" }, [
                          _c("legend", { staticClass: "text-center" }, [
                            _vm._v("Own team")
                          ])
                        ]),
                        _c("div", { staticClass: "form--group" }, [
                          _c("legend", { staticClass: "text-center" }, [
                            _vm._v("Opponent team")
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form--row" },
                        _vm._l(_vm.form.teams, function(team, idx) {
                          return _c(
                            "div",
                            { key: idx, staticClass: "form--group" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: team.name,
                                    expression: "team.name"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "team" + idx + "name"
                                },
                                domProps: { value: team.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(team, "name", $event.target.value)
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Submit")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1154804427
            )
          })
        : _c("ErrorAlert", [
            _vm._v("Sorry, but you don't have permission to edit scrims.")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }