var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.scrimId },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [_c("EditScrim", _vm._b({}, "EditScrim", data, false))]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }