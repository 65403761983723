<template>
  <div>
    <FormController v-if="canAccessScrimsV1" class="form" :submit="submit" :clear-on-submit="false">
      <template #default>
        <div class="form--group">
          <label for="title">Match name</label>
          <input
            type="text"
            class="form-control"
            id="title"
            minlength="3"
            maxlength="256"
            required
            v-model="form.name"
            placeholder="Pick a unique name - e.g. Augment's training team with coach as sub"
          />
        </div>

        <div class="form--row">
          <div class="form--group">
            <label for="date">Match date</label>
            <input type="date" class="form-control" id="date" required v-model="createdDate" />
          </div>
          <div class="form--group">
            <label for="time">Match time</label>
            <input type="time" class="form-control" id="time" required step="3600" v-model="createdTime" />
          </div>
        </div>

        <div class="form--group">
          <label for="map">Map</label>
          <MapCell :id="scrim.map" size="sm" variant="row" />
        </div>

        <hr />

        <div class="form--row">
          <div class="form--group">
            <legend class="text-center">Own team</legend>
          </div>
          <div class="form--group">
            <legend class="text-center">Opponent team</legend>
          </div>
        </div>

        <div class="form--row">
          <div v-for="(team, idx) in form.teams" :key="idx" class="form--group">
            <input type="text" :id="`team${idx}name`" class="form-control" v-model="team.name" />
          </div>
        </div>

        <button type="submit" class="btn btn-warning">Submit</button>
      </template>
    </FormController>
    <ErrorAlert v-else>Sorry, but you don't have permission to edit scrims.</ErrorAlert>
  </div>
</template>

<script>
import { format } from 'date-fns'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { editScrim } from '@/api/scrims'
import MapCell from '@/components/Table/cells/MapCell.vue'
import mixpanel from '@/mixpanel.js'
import reportException from '@/utils/reportException.js'

import FormController from '../controllers/FormController.vue'
import ErrorAlert from '../generic/ErrorAlert.vue'

export default {
  name: 'EditScrimForm',
  components: { ErrorAlert, FormController, MapCell },
  props: {
    scrim: px.object.isRequired,
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {
        created: '',
        teams: [],
        name: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      canAccessScrimsV1: 'auth/can_access_scrims_v1',
    }),
    sortedTeams() {
      return [...this.scrim.teams_through].sort(a => {
        return a.is_self_team ? -1 : 1
      })
    },
    createdDate: {
      get() {
        return format(new Date(this.form.created), 'yyyy-MM-dd')
      },
      set(value) {
        if (!value) return
        try {
          this.form.created = new Date(`${value}T${this.createdTime}`).toISOString()
        } catch (e) {
          reportException(e, { inputType: 'date', value })
        }
      },
    },
    createdTime: {
      get() {
        return format(new Date(this.form.created), 'HH:mm:ss')
      },
      set(value) {
        if (!value) return
        try {
          this.form.created = new Date(`${this.createdDate}T${value}`).toISOString()
        } catch (e) {
          reportException(e, { inputType: 'time', value })
        }
      },
    },
  },
  mounted() {
    this.form.name = this.scrim.name
    this.form.created = this.scrim.created

    this.sortedTeams.forEach(team => {
      const formTeam = {}
      formTeam.id = team.team
      formTeam.name = team.team_expand?.name
      this.form.teams.push(formTeam)
    })
  },
  methods: {
    async submit() {
      const res = await editScrim(this.scrim.id, this.form)

      mixpanel.track_scrim_edit(this.scrim)

      return res
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  max-width: 40em;

  &--row {
    display: flex;
    justify-content: space-between;
    gap: 4em;
  }

  &--group {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    margin-bottom: 1rem;
    flex: 1 1 auto;
  }
}
</style>
